import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"

const MeetingFinished = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Schedule New Horizon Meeting"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Schedule New Horizon Meeting"
      />
      <div className="section-title">
        <span className="sub-title">Schedule process finished</span>
        <h2>Thank you for booking the meeting!</h2>
        <p>
          Information you provided is crucial for our process of gathering and
          preparation of potential solutions for you. We hope to discuss your
          needs and how we can help in the upcoming meeting.
        </p>
      </div>

      <Footer />
    </Layout>
  )
}

export default MeetingFinished
